import React, { Fragment, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';


const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
      email: '',
      password: ''
    });
  
    const { email, password } = formData;
  
    const onChange = (e) =>
      setFormData({ ...formData, [e.target.name]: e.target.value });
  
    const onSubmit = async (e) => {
      e.preventDefault();
      login(email, password);
    };
  
    if(isAuthenticated) {
        return <Redirect to='/dashboard'/>;
    }
 
    return (
        <Fragment>
 
 <h1 className="form-group">Logg inn</h1>
    <form className="form-group" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
            <input type="email" placeholder="Email Address" name="email" value={email} onChange={e => onChange(e)} />
        </div>
        <div className="form-group" style={{textAlign: "center"}}>
            <input
                type="password"
                placeholder="Password"
                name="password"
                minLength="8"
                value={password} onChange={e => onChange(e)}
            />
        </div>
        <input type="submit" className="btn btn-light" value="Logg inn" />
    </form>
    <p className="form-group">
        Har du ikke opprettet en konto? <Link to="/register">Registrer deg her</Link>
    </p>





      
        </Fragment>
    )
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };
  
  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
  });
  
  export default connect(mapStateToProps, { login })(Login);