import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import StorbassengTable from "../table/Storbasseng";
import TerapibassengTable from "../table/Varmebasseng";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import "moment/locale/nb";
moment.locale("nb");

const TableView = ({ auth: { user } }) => {
  return (
    <Fragment>
      <div class="col-lg-12 text-center">
        <p className="lead">
          <i className="fas fa-user"></i> Velkommen, {user && user.name}
        </p>
      </div>
      <MDBContainer responsive>
        <MDBRow>
          <MDBCol  sm="6"><StorbassengTable /></MDBCol>
          
        <MDBCol sm ="6"><TerapibassengTable /></MDBCol>
          
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
};
TableView.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(TableView);
