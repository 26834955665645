import React from 'react'
import {Redirect, Link} from 'react-router-dom'; 
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import logo from "../../assets/newlogo.png";

const Landing = ({isAuthenticated}) => {

if(isAuthenticated) {
    return <Redirect to='/dashboard'/>
}


    return (
        <section className="landing">
                <div className="form-group">
                <h1 className="x-large" style={{ color: '#BADFE7' }}>Aqua
                        <strong style={{ color: '#ffae19', fontWeight: 'bolder', fontFamily: 'MuseoModerno' }}>Control</strong></h1>  
                <img alt="AquaControl_Logo" className="img-fluid" src={logo} />
                <div className="buttons">
                        <Link to="/register" className="btn btn-primary">Registrer deg</Link>
                        <Link to="/login" className="btn btn-light" >Logg Inn</Link>
                    </div>

                </div>
        </section>
    );
};
Landing.propTypes = {
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Landing);