import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import Landing from './components/layout/Landing';
import Alert from './components/layout/Alert';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import Vannprove from './components/dashboard/Vannprove';
import PrivateRoute from './components/routing/PrivateRoute';
import Navbar from './components/layout/Navbar';
import TableView from './components/table/TableView';
import Terapi from './components/sampleform/Terapibasseng';
import Storbasseng from './components/sampleform/Storbasseng';
import SideNav from './components/layout/SideNav';
import dSb from './components/dashboard/DB';




if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (
        <div className="flexible-content">
        <Provider store={store}>
            <Router>
                <Fragment>
                  <Navbar/>
        
                    <SideNav/>
                    <Alert />
                    <Switch>
                        <Route exact path='/' component={Landing} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute exact path="/Vannprove" component={Vannprove} />
                        <PrivateRoute exact path="/dashboard" component={dSb} />
                        <PrivateRoute exact path="/tableview" component={TableView} />
                        <PrivateRoute exact path="/storbasseng" component={Storbasseng}/>
                        <PrivateRoute exact path="/terapibasseng" component={Terapi}/>
                    </Switch>
                </Fragment>
            </Router>
        </Provider>
</div>


    );
};

export default App;