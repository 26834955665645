import React, { useState, useEffect } from 'react';
import { Line} from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/nb';
moment.locale('nb');


const Terapibasseng = () => {
    const [dataLine, setChartData] = useState({})
    const chart = async () => {
        let empDate = [];
        let empKlor = [];
        let empPh = [];
        let empOne = [];
        let empThree = [];
        let empPhenol = [];
        let empDiff = [];
        
       await axios
        .get('/api/terapibasseng')
        .then(res => {
            for (const dataObj of res.data) {
                empDate.push(moment(dataObj.date).format('lll'));
                empKlor.push(dataObj.panelc);
                empPh.push(dataObj.panelph);
                empOne.push(dataObj.dpdone);
                empThree.push(dataObj.dpdthree);
                empPhenol.push(dataObj.phenol);
                empDiff.push(dataObj.diff);
               
            }
        })
        setChartData({
    
            labels: empDate.toString().split(",").slice(0, 4),
            datasets: [
                
                {
                    label: 'Klor',
                    data: empKlor,
                    fill: false,
                    borderColor: [
                        '#808000'

                    ],
                     borderWidth: 3   
                    
                },
                {
                    label: 'PH',
                    data: empPh,
                    fill: false,
                    borderColor: [
                        '#DC143C'

                    ],
                     borderWidth: 1
                    
                },
                {
                    label: 'Dpd-1',
                    data: empOne,
                    fill: false,
                    borderColor: [
                        '#556B2F'

                    ],
                     borderWidth: 4   
                    
                },
                {
                    label: 'Dpd-3',
                    data: empThree,
                    fill: false,
                    borderColor: [
                        '#6B8E23'

                    ],
                     borderWidth: 4   
                    
                },
                {
                    label: 'DPD3-DPD1',
                    data: empDiff,
                    fill: false,
                    borderColor: [
                        '#9ACD32'

                    ],
                     borderWidth: 4   
                    
                },
                {
                    label: 'phenol',
                    data: empPhenol,
                    fill: false,
                    borderColor: [
                        '#B22222'

                    ],
                     borderWidth: 4   
                    
                },
            ],
        })
    }

    useEffect(() => {
        chart()
    }, [])
    return (
        
        <div className="card-content center">
        <div style={{width: "100%", height: "450px"}}>
           
        
       <Line data ={dataLine} options={{
           responsive: true,
           maintainAspectRatio: false,
           title: {text: 'Terapibasseng', display: true},
           scales: {
               yAxes: [
                   {
                       ticks: {
                           autoSkip: true,
                           maxTicksLimit: 20,
                           beginAtZero: true
                       },
                       gridLines: {
                           display: true
                       }
                   }
               ],
               xAxes: [
                   
                   {
                       ticks: {
                           autoSkip: true,
                           maxTicksLimit: 3,
                           beginAtZero: false
                       
                       },
                       gridLines: {
                           display: true
                       }
                   }
               ]
           }

           
       }}>
           
       </Line>
   </div>
   </div>
     
    )
}

export default Terapibasseng;