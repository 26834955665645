import React, {useState, useEffect, Fragment} from 'react'
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import { getStorbasseng } from '../../actions/storbasseng';
import {createSample} from '../../actions/storbasseng';




const SampleStorbasseng = ({createSample, history, getStorbasseng, storbasseng: {storbasseng, loading}}) => {
  useEffect(() => {
    getStorbasseng();
     
      
  }, );
const [formData, setFormData] = useState({
  
    panelph: '',
    panelc: '',
    dpdone: '',
    dpdthree: '',
    phenol: ''
});

const {
    panelph,
    panelc,
    dpdone,
    dpdthree,
    phenol
} = formData;

const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})

const onSubmit = e => {
    e.preventDefault();
    createSample(formData, history);
}
    return (
      <Fragment>
  

      <h1 style={{textAlign: "center"}} className="form-group">
        Storbasseng
      </h1>
    
    <form className="form-group" style={{textAlign: "center"}} onSubmit={e => onSubmit(e)}>
        <div className="form-group">
        </div>
        </form>


      <form className="form-group" style={{textAlign: "center"}} onSubmit={e => onSubmit(e)}>
        <div className="form-group">
        <div className="form-group">
          <small className="form-text">Les av verdien "Klor" på doseringsapparatet</small>
          <input type="number" placeholder="panel Klor" name="panelc" value={panelc} onChange={e => onChange(e)} />
        </div>
          <small className="form-text">Les av verdien "pH" på doseringsapparatet</small>
          <input type="number" id="form1" placeholder="panel pH" name="panelph" value={panelph} onChange={e => onChange(e)} />
        </div>
       
        <div className="form-group">
          <small className="form-text">Løs opp en DPD-1 pille i et glass og mål med vannprøvesettet</small>
          <input type="number" placeholder="DPD 1" name="dpdone" value={dpdone} onChange={e => onChange(e)} />
        </div>
        <div className="form-group">
          <small className="form-text">Løs opp en DPD-3 pille i samme glass og mål med vannprøvesettet</small>
          <input type="number" placeholder="DPD 3" name="dpdthree" value={dpdthree} onChange={e => onChange(e)} />
        </div>
        <small className="form-text">Løs opp en phenol pille i et glass og mål med vannprøvesettet</small>
        <div className="form-group">
          <input type="number" placeholder="Phenol" name="phenol" value={phenol} onChange={e => onChange(e)} />
        </div>
        <input type="submit" className="btn btn-primary" value="Send inn"/>
        <Link to="/vannprove" className="btn btn-dark" href="/vannprove">Avbryt</Link>
      </form>
   
    </Fragment>
    )
}

SampleStorbasseng.propTypes = {
createSample:PropTypes.func.isRequired,
storbasseng: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
  
    storbasseng: state.storbasseng,

})

export default connect(mapStateToProps, {createSample, getStorbasseng})(withRouter(SampleStorbasseng));
