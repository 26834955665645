import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';



 const Samples = () => {
    return (
   
<Fragment>

<div className="dash-buttons">
     
     <Link to="/terapibasseng" className="btn btn-primary">Terapibasseng</Link>
     
     <Link to="/storbasseng" className="btn btn-primary">Storbasseng</Link>
    
   </div>
</Fragment>
    )
}
export default Samples;