import axios from 'axios';
import { setAlert } from './alert';

import {
    GET_ANLEGG,
    ANLEGG_ERROR
} from './types';

export const getStorbasseng = () => async dispatch => {
    try {
        const res = await axios.get('/api/storbasseng');
        dispatch({
            type: GET_ANLEGG,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: ANLEGG_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}
// Create or update anlegg 
export const createSample = (formData, history, edit = false) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const res = await axios.post('/api/storbasseng', formData, config);
        dispatch({
            type: GET_ANLEGG,
            payload: res.data
        });
        dispatch(setAlert(edit ? 'Vannprøve registrert':'Vannprøve registrert', 'success'));
        if(!edit) {
            history.push('./vannprove');
        }
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: ANLEGG_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}