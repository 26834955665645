import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import DashBoardActions from './DashboardActions';




const Vannprove = ({ auth: { user } }) => {



    return <Fragment>
      <div class="col-lg-12 text-center">
        <h1 className="large text-primary">
            Velg Anlegg
      </h1>
        <p className="lead">
            <i className="fas fa-user"></i> Velkommen, {user && user.name}
        </p>
        <Fragment><DashBoardActions /></Fragment>
        </div>
    </Fragment>;

};


Vannprove.propTypes = {

    auth: PropTypes.object.isRequired,



}


const mapStateToProps = state => ({
    auth: state.auth


})
export default connect(mapStateToProps, {})(Vannprove);
