import React, { useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from 'react-csv';
import { MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBTableHead, MDBRow, MDBCol, MDBCardHeader } from 'mdbreact';
import moment from "moment";
import "moment/locale/nb";
moment.locale("nb");

const Storbasseng = () => {
  const [samples, setSamples] = useState([]);


  const getData = async () => {
    const response = await axios.get("/api/storbasseng");
    console.log(response);
    setSamples(response.data);
  };
  const renderHeader = () => {

    let headerElement = ['Dato/Tid', 'panelph', 'panelc', 'dpdone', 'dpdthree', 'diff', 'phenol', 'navn']

    return headerElement.map((key, index) => {
      return <th style={{ backgroundColor: "transparent" }} key={index}>{key.toUpperCase()}</th>
    })
  }

  const renderBody = () => {
    return samples && samples.map(({ date, panelph, panelc, dpdone, dpdthree, diff, phenol, name }) => {
      const newDate = moment(date).format('lll');
      const newDiff = parseFloat(diff).toFixed(2);

      return (
        <tr key={newDate}>
          <td >{newDate}</td>
          <td>{panelph}</td>
          <td>{panelc}</td>
          <td>{dpdone}</td>
          <td>{dpdthree}</td>
          <td>{newDiff}</td>
          <td>{phenol}</td>
          <td>{name}</td>
        </tr>

      )
    })
  }


  useEffect(() => {
    getData();
  }, []);
  return (


    <MDBRow className="mb-5" style={{ float: 'center', responsive: "true" }}>
      <MDBCol lg="12" sm="2">
        <MDBCard>
          <MDBCardBody style={{ fontSize: "12px", textAlign: 'center', width: '100%' }}>
            <MDBCardHeader style={{ fontSize: "16px" }}>Storbasseng
      </MDBCardHeader>
            <CSVLink
              data={samples}
              filename={"storbasseng.csv"}
              target="_blank"
            >
              last ned til excel
</CSVLink>

            <MDBTable scrollY
              maxHeight="300px"
              striped
              bordered
              small
              responsive
              autoWidth
            >
              <MDBTableHead color="blue-grey lighten-4">
                <tr>{renderHeader()}</tr>
              </MDBTableHead>
              <MDBTableBody>
                {renderBody()}
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>








  );
};
export default Storbasseng;
