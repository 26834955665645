import React, { Fragment } from "react";
import StorbassengChart from './Storbasseng';
import TerapibassengChart from './Terapibasseng';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MDBContainer } from 'mdbreact';
import moment from "moment";
import "moment/locale/nb";
moment.locale("nb");


const ChartView = ({ auth: { user } }) => {
  return <Fragment>
    <div class="col-lg-12 text-center">
      <p className="lead">
        <i className="fas fa-user"></i> Velkommen, {user && user.name}
      </p>
    </div>
    <MDBContainer>
     
          <StorbassengChart />
        
          <TerapibassengChart />
    
      </MDBContainer>
    
  
  </Fragment>;
}

ChartView.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ChartView);