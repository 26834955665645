import { GET_ANLEGG, ANLEGG_ERROR, CLEAR_ANLEGG } from '../actions/types';

const initialState = {
    panelph: null,
    panelc: null,
    dpdone: null,
    dpdthree: null,
    diff: null,
    phenol: null

}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ANLEGG:
            return {
                ...state,
                ovre: payload,
                loading: false
            };
        case ANLEGG_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
            case CLEAR_ANLEGG: 
            return {
                ...state,
                ovre: null,
                loading: false
            }
        default:
            return state;

    }
}