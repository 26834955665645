import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types'



const Register = ({ setAlert, register, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
    });

    const { name, email, password, password2 } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        if (password !== password2) {
            setAlert("passwords do not match", 'danger');
        } else {
            register({ name, email, password });
        }
    };

      //REDIRECT IF LOGGED IN 
      if(isAuthenticated) {
        return <Redirect to='/dashboard' />   
    }

    return (
        <Fragment>
       
                <h1 className="form-group">Registrer deg</h1>
                <form className="form-group" onSubmit={e => onSubmit(e)}>
                    <div className="form-group">
                        <input type="text" placeholder="Navn" name="name" value={name} onChange={e => onChange(e)} required />
                    </div>
                    <div className="form-group">
                        <input type="email" placeholder="Epost" name="email" value={email} onChange={e => onChange(e)} />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Passord"
                            name="password"
                            minLength="8"
                            value={password} onChange={e => onChange(e)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Gjenta Passord"
                            name="password2"
                            minLength="8"
                            value={password2} onChange={e => onChange(e)}
                        />
                    </div>
                    <input type="submit" className="btn btn-primary" value="Registrer" />
                </form>
                <p className="form-group">
                    Allerede registrert deg? <Link to="/login">Logg Inn</Link>
                </p>
         
        </Fragment>
    )
}

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { setAlert, register })(Register);