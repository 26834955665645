import React, { Fragment } from 'react';
import logo from "../../assets/newlogo.png";

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';


const SideNav = ({ auth: { isAuthenticated, loading }, logout }) => {
    const authLinks = (
        <MDBListGroup className="list-group-flush">
            <NavLink to="/vannprove" activeClassName="activeClass">
                <MDBListGroupItem>
                    <MDBIcon icon="fas fa-tint" className="mr-3" />
                        Vannprøve
                    </MDBListGroupItem>
            </NavLink>
            <NavLink exact={true} to="/dashboard" activeClassName="activeClass">
                <MDBListGroupItem>
                    <MDBIcon icon="chart-line" className="mr-3" />
                        Graf
                    </MDBListGroupItem>
            </NavLink>
            <NavLink to="/tableview" activeClassName="activeClass">
                <MDBListGroupItem>
                    <MDBIcon icon="table" className="mr-3" />
                        Tabell
                    </MDBListGroupItem>
            </NavLink>
            <NavLink to="/">
                <MDBListGroupItem>
                    <MDBIcon onClick={logout} icon="fas fa-sign-out-alt" className="mr-3" />
                    <a onClick={logout} href='/'>Logg ut</a>
                </MDBListGroupItem>
            </NavLink>
        </MDBListGroup>
    );
    const guestLinks = (
        <MDBListGroup className="list-group-flush">
            <NavLink to="/register">
                <MDBListGroupItem>
                    <MDBIcon icon="user-plus" className="mr-3" />
                        Registrer deg
                    </MDBListGroupItem>
            </NavLink>
            <NavLink to="/login">
                <MDBListGroupItem>
                    <MDBIcon icon="sign-in-alt" className="mr-3" />
                        Logg Inn
                    </MDBListGroupItem>
            </NavLink>
        </MDBListGroup>

    );
    return (
<Fragment>
        <div className="sidebar-fixed position-fixed">
            <a href="/" className="logo-wrapper waves-effect">
                <img alt="AquaControl_Logo" className="img-fluid" src={logo} />
            </a>
            {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
        </div>
    


        </Fragment>


    );
}
SideNav.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, { logout })(SideNav);