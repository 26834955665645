import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import storbasseng from './storbasseng';
import terapibasseng from './terapibasseng';


export default combineReducers({
    alert,
    auth,
    storbasseng,
    terapibasseng
});