import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { MDBNavbar, MDBIcon } from 'mdbreact';

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
  const authLinks = (
    <ul>
         <li>
         <Link to='/vannprove'>
         <MDBIcon icon="fas fa-tint" className="mr-3"/>
          
        </Link>
      </li>
      <li>
        <Link to='/dashboard'>
        <MDBIcon icon="chart-line" className="mr-3"/>{' '}
        </Link>
      </li>
      <li>
        <Link to='/tableview'>
        <MDBIcon icon="table" className="mr-3"/>
          
        </Link>
      </li>
      <li>
        <a onClick={logout} href='#1'>
          <i className='fas fa-sign-out-alt' />{' '}
          <Link to ="/"></Link> 
        </a>
      </li>
    </ul>

  );

  const guestLinks = (
 
    <ul>
 
    </ul>

  );
  return (


    <MDBNavbar >
      {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
      </MDBNavbar>
    
  )
}
Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logout })(Navbar);