import React from 'react';

import ChartView from '../dashboard/charts/ChartView';



const DashboardPage =  () => {
  return (
    <React.Fragment>
          <ChartView/>
    </React.Fragment>
  )
}

export default DashboardPage;